import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from 'src/app/shared/navigation/navigation.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { UtilService } from 'src/app/shared/util/util.service';
import { AdminService } from '../shared/admin.service';
import { AuthService } from 'src/app/auth/shared/auth.service';
import { UserSession } from 'src/app/auth/shared/user-session.model';
import { RabTenantDto } from '../shared/dto/rab-tenant-dto';
import { TenantDatasetDto } from '../shared/dto/tenant-dataset-dto';
import { NameValueNumberDto } from 'src/app/shared/dto/name-value-number-dto';
import { ValidationResultDto } from 'src/app/shared/dto/validation-result-dto';
import { McTradeService } from 'src/app/products/mctrade/shared/mctrade.service';
import { TenantDatasetColumnDto } from '../shared/dto/tenant-dataset-column-dto';
import { TenantDatasetMetaDataDto } from '../shared/dto/tenant-dataset-metadata-dto';
import { ValidationResultWithReturnSuccessResultDto } from 'src/app/shared/dto/validation-result-with-success-result-dto';
import { NameValueStringDto } from 'src/app/shared/dto/name-value-string-dto';


@Component({
  selector: 'admin-tenant-dataset',
  templateUrl: './admin-tenant-dataset.component.html',
  styleUrl: './admin-tenant-dataset.component.css'
})



export class AdminTenantDatasetComponent implements OnInit {

  //@Input({ required: true }) selectedTenant: RabTenantDto;

  public selectedTenant: RabTenantDto = new RabTenantDto();
  public selectedDataset: TenantDatasetDto = new TenantDatasetDto();
  public datasets: Array<TenantDatasetDto> = new Array<TenantDatasetDto>();

  public selectedProduct: string = 'mc_trade'
  public isNewDatasetRequest: boolean = false;
  public showDialog: boolean = false;
  public currentUserSession: UserSession = new UserSession

  public datasetTypes: Array<NameValueNumberDto> = new Array<NameValueNumberDto>();
  public validationErrors: string = "";
  public testSqlResultJson: string;

  public isValidDataset: boolean = false;
  public isValidating: boolean = false;

  private _currentProductId: string = "";
  private _currentTenantId: string = "";


  public currentTenantDatasetColumnMetaData = new Array<TenantDatasetColumnDto>();
  public dataRoles = new Array<string>();

  constructor(
    private _adminService: AdminService,
    private _navigationService: NavigationService,
    private _utilService: UtilService,
    private _authService: AuthService,
    private _notificationService: NotificationService,
    private _mctradeService: McTradeService
  ) {

  }

  ngOnInit() {

    this.datasetTypes = new Array<NameValueNumberDto>();
    var nv1 = new NameValueNumberDto();
    nv1.name = "Static Data";
    nv1.value = 0;

    var nv2 = new NameValueNumberDto();
    nv2.name = "Transaction Data (Date Based)";
    nv2.value = 1;

    this.datasetTypes.push(nv1);
    this.datasetTypes.push(nv2);

    this.dataRoles.push("DIMENSION");
    this.dataRoles.push("MEASURE");


    this._adminService.selectedTenant.subscribe((tenant: RabTenantDto) => {

      this.selectedTenant = tenant;
      this.getDatasets();

    })


  }

  getDatasets() {
    this._adminService.getDatasets(this.selectedTenant.productId, this.selectedTenant.tenantId).then((data: Array<TenantDatasetDto>) => {
      this.datasets = data;
    });
  }

  validateDataset() {
    this.isValidDataset = false;
    this.isValidating = true;
    this.validationErrors = "";

    this._adminService.validateDataset(this.selectedDataset).then((data: ValidationResultWithReturnSuccessResultDto) => {

      this.isValidating = false;

      if (data.isValid == false) {

        for (let error of data.validationErrors) {

          this.validationErrors = this.validationErrors + " " + error;
        }

      } else {

        this.isValidDataset = true;

        if (data.successResult) {
          var colnames = <Array<NameValueStringDto>>data.successResult;

          var existingMetaDataColumns = this._utilService.clone(this.currentTenantDatasetColumnMetaData);

          this.currentTenantDatasetColumnMetaData = new Array<TenantDatasetColumnDto>();
          for (var colname of colnames) {

            var existingMetaDataColumn = existingMetaDataColumns.find((element: TenantDatasetColumnDto) => this._utilService.toLowerTrim(element.name) == this._utilService.toLowerTrim(colname.name));


            var meta = new TenantDatasetColumnDto();
            meta.name = colname.name;
            meta.dataType = colname.value;


            if (this._utilService.isNotNullOrUndefined(existingMetaDataColumn)) {

              meta.aliasesCsv = existingMetaDataColumn.aliasesCsv;
              meta.valueAliases = existingMetaDataColumn.valueAliases;

              if (this._utilService.isNullEmptyOrWhitespace(existingMetaDataColumn.role)) {

                meta.role = this.getRoleFromDataType(colname.value);
              } else {
                meta.role = existingMetaDataColumn.role;
              }

            } else {
              meta.role = this.getRoleFromDataType(colname.value);
            }

            this.currentTenantDatasetColumnMetaData.push(meta);

          }


        }



      }
    }, () => { this.isValidating = false; });
  }

  getRoleFromDataType(dataType: string) {
    if (this._utilService.toLowerTrim(dataType) == this._utilService.toLowerTrim("Int32")) {
      return "DIMENSION";
    } else if (this._utilService.toLowerTrim(dataType) == this._utilService.toLowerTrim("DateTime")) {
      return "DIMENSION";
    } else if (this._utilService.toLowerTrim(dataType) == this._utilService.toLowerTrim("Double")) {
      return "MEASURE";
    } else if (this._utilService.toLowerTrim(dataType) == this._utilService.toLowerTrim("String")) {
      return "DIMENSION";
    } else {
      return "DIMENSION";
    }
  }
  upsertDataset() {

    this.validationErrors = "";
    this.selectedDataset.changedBy = this._authService.getUserSession().userName;

    var metaDataObject = new TenantDatasetMetaDataDto();
    if (this.currentTenantDatasetColumnMetaData.length > 0) {

      for(let col of this.currentTenantDatasetColumnMetaData){
        if(this._utilService.isNotNullEmptyOrWhitespace(col.aliasesCsv) || col.valueAliases.length > 0){
          //only add them if there is a value
          metaDataObject.columns.push(col)
        }
        
      }
      
      this.selectedDataset.metaDataObject = metaDataObject
    } else {
      this.selectedDataset.metaData = "";
      this.selectedDataset.metaDataObject = metaDataObject;
    }

    this._adminService.upsertDataset(this.selectedDataset);

    this.getDatasets();
    this.showDialog = false;


  }

  selectDataset(dataset: TenantDatasetDto) {

    this.isNewDatasetRequest == false;
    this.selectedDataset = dataset;
    this.showDialog = true;

    this.currentTenantDatasetColumnMetaData == new Array<TenantDatasetColumnDto>();
    if (this._utilService.isNotNullEmptyOrWhitespace(dataset.metaData)) {
      var metaDataObj = <TenantDatasetMetaDataDto>JSON.parse(dataset.metaData);

      for (var col of metaDataObj.columns) {
        var m = new TenantDatasetColumnDto();
        this._utilService.copyObjectPropertyValues(col, m);
        
        this.currentTenantDatasetColumnMetaData.push(m);
      }


    }
    this.validateDataset();


  }
  cancel() {
    this.isNewDatasetRequest == false;
    this.showDialog = false;
  }

  deleteDataset(dataset: TenantDatasetDto) {

    this._adminService.deleteDataset(dataset).then(() => {

      this.getDatasets();

    });

  }

  newDataset() {
    this.isNewDatasetRequest = true;
    this.selectedDataset = new TenantDatasetDto();
    this.selectedDataset.id = "";
    this.selectedDataset.name = "New Dataset Name";
    this.selectedDataset.tenantId = this.selectedTenant.tenantId;
    this.selectedDataset.productId = this.selectedTenant.productId;
    this.selectedDataset.descr = "";
    this.selectedDataset.sql = "";
    this.currentTenantDatasetColumnMetaData = new Array<TenantDatasetColumnDto>();
    this.isValidDataset = false;
    this.showDialog = true;
  }

  testSql() {
    this._mctradeService.GetTenantDataQuery(this.selectedTenant.tenantId, this.selectedDataset.sql).then((data: any) => {

      this.testSqlResultJson = JSON.stringify(data);

    });

  }

  // addMetaData() {
  //   this.currentTenantDatasetColumnMetaData.push(new TenantDatasetColumnDto());
  // }
  removeMetaData(item: TenantDatasetColumnDto) {

    this.currentTenantDatasetColumnMetaData = this._utilService.arrayRemoveEntry(this.currentTenantDatasetColumnMetaData, item);
  }

  addValueAlias(item: TenantDatasetColumnDto) {


    item.valueAliases.push(new NameValueStringDto())
  }

  removeValueAlias(valueAliases: Array<NameValueStringDto>, valueAlias: NameValueStringDto) {


    valueAliases = this._utilService.arrayRemoveEntry(valueAliases, valueAlias);
  }

}
