import { TenantDatasetMetaDataDto } from "./tenant-dataset-metadata-dto";

export class TenantDatasetDto {

        public constructor() {
                this.metaData = "";
                this.metaDataObject = new TenantDatasetMetaDataDto();
                this.notifyEmail = "";
        }

        id: string;
        tenantId: string;
        productId: string;
        sql: string;
        name: string;
        descr: string;
        datasetType: number;
        dateChangedExpression: string;
        tranDateExpression: string
        changedBy: string;
        dateCreated: string;
        dateChanged: string;
        dataEntityNameResourceSafe: string;
        topicId: string;
        metaData: string;
        metaDataObject: TenantDatasetMetaDataDto;
        notifyEmail: string;

}


