<admin-auth></admin-auth>
<div class="container">


  <!-- <div class="text-center mb-5">

    <div class="text-900 text-3xl font-medium mb-3">Custom Datasets</div>
  </div> -->


  <div class="grid">
    <div class="col-6">
      <p-button id="btnNew" i18n-label label="New Dataset" (click)="newDataset()" icon="pi pi-plus-circle"
        class="w-auto"></p-button>
    </div>
    <div class="col-6">

    </div>

  </div>

  <p-dialog [style]="{width: '95%'}" [(visible)]="showDialog" [modal]="true" [keepInViewport]="true" [resizable]="true"
    [maximizable]="true">
    <ng-template pTemplate="header">

    </ng-template>

    <div class="grid">

      <div class="col-3">
        <label i18n for="id">Id</label>
      </div>
      <div class="col-9">
        <label id="id" i18n>{{selectedDataset.id}}</label>
      </div>


      <div class="col-3">
        <label i18n for="name">Name</label>
      </div>
      <div class="col-9">
        <input id="name" class="w-full" type="text" pInputText [(ngModel)]="selectedDataset.name">
      </div>

      <div class="col-3">
        <label i18n for="descr">Description</label>
      </div>
      <div class="col-9">
        <input id="descr" class="w-full" type="text" pInputText [(ngModel)]="selectedDataset.descr">
      </div>

      <div class="col-3">
        <label i18n for="datasetType">Dataset Type</label>
      </div>
      <div class="col-9">
        <p-dropdown id="datasetType" class="w-full" [options]="datasetTypes" optionLabel="name" optionValue="value"
          [(ngModel)]="selectedDataset.datasetType"></p-dropdown>
      </div>


      <div class="col-3">
        <label i18n for="sql">Sql</label>
        <br />
        <small i18n>Nulls field values are not allowed. Use ISNULL or other statements to prevent the output from
          containing null values. Placeholders [[StartDate]] [[EndDate]] and [[AsOfDate]] may be used</small>

      </div>
      <div class="col-9">

        <textarea id="sql" pInputTextarea [(ngModel)]="selectedDataset.sql" [rows]="10" style="width:100%"></textarea>
      </div>

      <!-- <div class="col-3">
        <p-button id="btnTestSql" i18n-label label="Test Sql" (click)="testSql()" icon="pi pi-sparkles"
        class="w-auto"></p-button>
      </div>
      <div class="col-9">

        <pre></pre>
      </div> -->

      <!-- <div class="col-3">
        <label i18n for="tranDateExpression" [hidden]="selectedDataset.datasetType != 1">Transaction Date
          Expression</label>
      </div>
      <div class="col-9">

        <textarea id="tranDateExpression" [hidden]="selectedDataset.datasetType != 1" pInputTextarea
          [(ngModel)]="selectedDataset.tranDateExpression" [rows]="10" style="width:100%"></textarea>
      </div> -->

      <div class="col-3" [hidden]="selectedDataset.datasetType != 1">
        <label i18n for="dateChangedExpression">Date Changed Expression</label>
        <br />
        <small i18n>Full sql select statement that must output 3 columns in this order : year,month,day. Placeholders
          [[StartDate]] [[EndDate]] and [[AsOfDate]] may be used</small>

      </div>
      <div class="col-9" [hidden]="selectedDataset.datasetType != 1">

        <textarea id="dateChangedExpression" pInputTextarea [(ngModel)]="selectedDataset.dateChangedExpression"
          [rows]="10" style="width:100%"></textarea>
      </div>


      <div class="col-3">
        <label i18n>Meta Data</label>
      </div>
      <div class="col-9">

        <p-table [value]="currentTenantDatasetColumnMetaData" styleClass="p-datatable-gridlines"
          responsiveLayout="scroll">
          <!-- <ng-template pTemplate="caption">
      
          </ng-template> -->
          <ng-template pTemplate="header">
            <tr>

              <th i18n>Column Name</th>
              <!-- <th i18n>Description</th> -->
              <th i18n>Role</th>
              <th i18n>Aliases (value1, value2, value3)</th>
              <th i18n>Id Column</th>
              <th i18n> </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>

              <td> <input id="name" [disabled]="true" class="w-full" type="text" pInputText [(ngModel)]="item.name">
              </td>
              <!-- <td> <input id="descr" class="w-full" type="text" pInputText [(ngModel)]="item.description"></td> -->
              <td>
                <p-dropdown id="role" class="w-full" [options]="dataRoles" [(ngModel)]="item.role"></p-dropdown>
              </td>
              <td> <input id="aliases" class="w-full" type="text" pInputText [(ngModel)]="item.aliasesCsv"></td>
              <td> <p-checkbox [(ngModel)]="item.isIdColumn" [binary]="true"></p-checkbox></td>
              <td>
                <p-button id="addValueAlias" [hidden]="item.dataType != 'Boolean'" i18n-label
                  label="Add Field Value Alias" (click)="addValueAlias(item)" icon="pi pi-sparkles"
                  class="w-auto"></p-button>
                <p-table [hidden]="item.dataType != 'Boolean'" [value]="item.valueAliases"
                  styleClass="p-datatable-gridlines" responsiveLayout="scroll">
                  <ng-template pTemplate="header">
            <tr>

              <th i18n>Value</th>
              <th i18n>Value Aliases (value1, value2, value3)</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-valueAlias>
            <tr>

              <td> <input id="value" class="w-full" type="text" pInputText [(ngModel)]="valueAlias.name">
              </td>
              <td> <input id="value" class="w-full" type="text" pInputText [(ngModel)]="valueAlias.value">
              </td>
              <td><p-button id="removeValueAlias" i18n-label label="Remove"
                  (click)="removeValueAlias(item.valueAliases, valueAlias)" icon="pi pi-sparkles"
                  class="w-auto"></p-button></td>
            </tr>
          </ng-template>

        </p-table>

        </td>
        <!-- <td> <p-button id="removeAddMetaData" i18n-label label="Remove" (click)="removeMetaData(item)" icon="pi pi-sparkles"
                class="w-auto"></p-button></td>       -->

        </tr>
        </ng-template>
        <ng-template pTemplate="summary">

        </ng-template>
        </p-table>

      </div>

    </div>

    <ng-template pTemplate="footer">
      <label i18n>Notify Email</label>&nbsp;
      <input id="value" type="text" pInputText [(ngModel)]="selectedDataset.notifyEmail">&nbsp;
      <p-button id="btnValidate" [hidden]="isValidDataset == true" [disabled]="isValidating == true" i18n-label
        label="Validate" (click)="validateDataset()" icon="pi pi-check" class="w-auto"></p-button>
      <p-button id="btnSave" [hidden]="isValidDataset == false" i18n-label label="Save" (click)="upsertDataset()"
        icon="pi pi-check-circle" class="w-auto"></p-button>
      <p-button id="btnCancel" i18n-label label="Cancel" (click)="cancel()" icon="pi pi-ban" class="w-auto"></p-button>     
      <div>{{validationErrors}}</div>
    </ng-template>
  </p-dialog>






  <p-table [value]="datasets" styleClass="p-datatable-gridlines" responsiveLayout="scroll">
    <ng-template pTemplate="caption">

    </ng-template>
    <ng-template pTemplate="header">
      <tr>

        <th i18n>Name</th>
        <th i18n>id</th>
        <th i18n>Description</th>
        <!-- <th i18n>TenantId</th>
                <th i18n>ProductId</th> -->
        <th></th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>

        <td>{{item.name}}</td>
        <td>{{item.id}}</td>
        <td>{{item.descr}}</td>
        <!-- <td>{{item.tenantId}}</td>
                <td>{{item.productId}}</td> -->

        <td><p-button styleClass="p-button-link" type="button" i18n-label label="edit" class="p-button-text"
            (click)="selectDataset(item)"></p-button></td>
        <td> <p-button id="btnDelete" styleClass="p-button-link" i18n-label label="delete"
            (click)="deleteDataset(item)"></p-button></td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">

    </ng-template>
  </p-table>





</div>