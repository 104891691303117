export class NameValueStringDto {
    public constructor() {
  
      this.name = "";
      this.value = "";
     
    }
  
    public  name : string
    public  value : string
   
  
  }