import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() { }

  clone = (object:any): any =>{
    var obj = structuredClone(object);
    return obj;
  }

  mapSafe = (sourceObject: any, destinationObject: any): void => {
    //only copys the properties of the source object if they are found on the destination object
    //and they are primitive.
    //this prevents the changing of the destination object structure and property data types
    if (sourceObject != undefined && sourceObject != null && destinationObject != undefined && destinationObject != null) {
      for (var key in sourceObject) {

        if (!destinationObject.hasOwnProperty(key)) {
          continue;
        } else {
          if (this.isPrimitive(sourceObject[key])) {
            if (this.isNotNullOrUndefined(sourceObject[key])) {
              var typeSource = typeof sourceObject[key];
              var typeDestination = typeof destinationObject[key];
              if (typeSource == typeDestination) {
                //only copy if not null undefined and same type
                destinationObject[key] = cloneDeep(sourceObject[key]); //sourceObject[key];
              }
            }
          } else {
            this.mapSafe(sourceObject[key], destinationObject[key]);
          }
        }
      }
    }
  };


  startsWith(input: string, test: string): boolean {
    if (!input) {
      return false;
    }

    return input.indexOf(test) === 0;
  }

  containsAnyCase(input: string, test: string): boolean {
    if (!input) {
      return false;
    }

    return input.toLowerCase().indexOf(test.toLowerCase()) > -1;
  }

  toLowerTrim(str: string): string {
    if (str == null) {
      return str;
    }

    str = str.replace(/^\s+|\s+$/g, '');
    return str.toLowerCase();
  }

  convertJsonUtcServerDateStringsToDates(object: any): void {
    /**
     * RegExp to test a string for a ISO 8601 Date spec
     *  YYYY
     *  YYYY-MM
     *  YYYY-MM-DD
     *  YYYY-MM-DDThh:mmTZD
     *  YYYY-MM-DDThh:mm:ssTZD
     *  YYYY-MM-DDThh:mm:ss.sTZD
     * @see: https://www.w3.org/TR/NOTE-datetime
     * @type {RegExp}
     */
    const ISO_8601 = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;

    for (const key in object) {
      if (!object.hasOwnProperty(key)) {
        continue;
      }

      const value = object[key];
      if (value === undefined || value == null) {
        continue;
      }

      let match: any;
      // Check for string properties which look like dates.
      let matchFound = false;
      match = match = value.match(ISO_8601);

      if (typeof value === 'string' && value.length >= 19 && match != null) {
        matchFound = true;
      }

      if (!matchFound) {
        if (typeof value === 'object') {
          // Recurse into object
          this.convertJsonUtcServerDateStringsToDates(value);
        }
      }

      if (matchFound) {
        const proposedDate = new Date(value);
        if (proposedDate.getFullYear() > 1751) {
          // make sure this doesn't come up with something like
          // Sat Dec 31 1233 19:00:00 GMT-0500 (Eastern Standard Time)
          // which it would if trying to parse a ZipExt string field
          object[key] = proposedDate;
        }
      }
    }
  }

  parseJsonDate(json: string): Date {
    let dateStr: string = JSON.parse(json);
    let dte: Date = new Date(dateStr);

    return dte;
  }

  
  getQueryStringParamValue(paramName: string, expectedType: string, url?: string): any {
    if (!expectedType) {
      expectedType = 'string';
    }
    if (!url) {
      url = window.location.href;
    }

    let value: any = null;
    let queryIndex: number = url.indexOf('?');

    if (queryIndex >= 0) {
      const urlQuery = url.substr(queryIndex);
      if (urlQuery) {
        let paramItems: any[] = urlQuery.substr(1).split('&');

        for (var iParamIndex = 0; iParamIndex < paramItems.length; iParamIndex++) {
          let paramItem: any = paramItems[iParamIndex];
          let paramItemArray: any[] = paramItem.split('=');
          if (paramItemArray != undefined) {
            if (paramItemArray[0] != undefined) {
              let key: any = paramItemArray[0];
              if (key.toLowerCase() == paramName.toLowerCase()) {
                if (paramItemArray[1] != undefined) {
                  value = decodeURIComponent(paramItemArray[1]);
                }
              }
            }
          }
        }
      }
    }

    if (value != null) {
      if (expectedType.toLowerCase() == 'boolean') {
        if (value.toLowerCase() == 'true') {
          value = true;
        } else {
          value = false;
        }
      }

      if (expectedType.toLowerCase() == 'number') {
        if (value == '') {
          value = 0;
        } else {
          try {
            value = parseFloat(value);
          } catch (err) {
            value = 0;
          }
        }
      }

      if (expectedType.toLowerCase() == 'date') {
        if (value == '') {
          value = new Date();
        }
      }
    }
    return value;
  }

  hasQueryStringParam(paramName: string, url?: string): boolean {

    if (!url) {
      url = window.location.href;
    }

    let value: any = null;
    let queryIndex: number = url.indexOf('?');

    if (queryIndex >= 0) {
      const urlQuery = url.substr(queryIndex);
      if (urlQuery) {
        let paramItems: any[] = urlQuery.substr(1).split('&');

        for (var iParamIndex = 0; iParamIndex < paramItems.length; iParamIndex++) {
          let paramItem: any = paramItems[iParamIndex];
          let paramItemArray: any[] = paramItem.split('=');
          if (paramItemArray != undefined) {
            if (paramItemArray[0] != undefined) {
              let key: any = paramItemArray[0];
              if (key.toLowerCase() == paramName.toLowerCase()) {
                if (paramItemArray[1] != undefined) {
                  value = decodeURIComponent(paramItemArray[1]);
                }
              }
            }
          }
        }
      }
    }

    if (value != null) {
      return true;
    } else {
      return false;
    }
  }

  appendQueryStringParam(paramName: string, paramValue: string, url?: string): string {

    if (!url) {
      url = window.location.href;
    }

    if (url.indexOf("?") > 0) {
      url = url + "&" + paramName + "=" + paramValue;
    } else {
      url = url + "?" + paramName + "=" + paramValue;
    }

    return url;
  }

  getHashParamValue(paramName: string, expectedType: string, url: string): any {
    if (!expectedType) {
      expectedType = 'string';
    }
    if (!url) {
      url = window.location.href;
    }

    let value: any = null;
    let hashIndex: number = url.indexOf('#');

    if (hashIndex >= 0) {
      const urlHash = url.substr(hashIndex);
      if (urlHash) {
        let paramItems: any[] = urlHash.substr(1).split('&');

        for (var iParamIndex = 0; iParamIndex < paramItems.length; iParamIndex++) {
          let paramItem: any = paramItems[iParamIndex];
          let paramItemArray: any[] = paramItem.split('=');
          if (paramItemArray != undefined) {
            if (paramItemArray[0] != undefined) {
              let key: any = paramItemArray[0];
              if (key.toLowerCase() == paramName.toLowerCase()) {
                if (paramItemArray[1] != undefined) {
                  value = decodeURIComponent(paramItemArray[1]);
                }
              }
            }
          }
        }
      }
    }

    if (value != null) {
      if (expectedType.toLowerCase() == 'boolean') {
        if (value.toLowerCase() == 'true') {
          value = true;
        } else {
          value = false;
        }
      }

      if (expectedType.toLowerCase() == 'number') {
        if (value == '') {
          value = 0;
        } else {
          try {
            value = parseFloat(value);
          } catch (err) {
            value = 0;
          }
        }
      }

      if (expectedType.toLowerCase() == 'date') {
        if (value == '') {
          value = new Date();
        }
      }
    }
    return value;
  }

  toTitleCase(str: string): string {
    if (str == null) return str;

    return str.replace(new RegExp(/^\w+\b|(?!\bthe\b|\band\b|\ba\b|\ban\b|\bin\b|\bof\b|\bd'\b|\bye\b)\b\w+/g), function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  toCapitalizedFirstCharacter(str: string): string {
    if (str == null) return str;

    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }

  isNullOrUndefined(object: any): boolean {
    let bol: boolean = false;
    if (object == undefined) {
      bol = true;
    } else {
      if (object == null) {
        bol = true;
      }
    }

    return bol;
  }

  isNotNullOrUndefined(object: any): boolean {
    return !this.isNullOrUndefined(object);
  }
  isPrimitive = (arg: any): boolean => {
    if (arg == undefined) {
      return false;
    } else if (Array.isArray(arg)) {
      return false;
    } else if (arg instanceof Date) {
      return true;
    } else {
      var type = typeof arg;
      return arg == null || (type != 'object' && type != 'function');
    }
  };


  /*string */
  isNumeric(text: string): boolean {
    let result: boolean = false;
    if (text != undefined && text != null) {
      if (!isNaN(parseFloat(text))) {
        var parsedNum: number = parseFloat(text);
        result = isFinite(parsedNum);
      }
    }

    return result;
  }

  toNumber(text: string): number {
    let result: number = 0;
    if (text != undefined && text != null) {
      if (!isNaN(parseFloat(text))) {
        result = parseFloat(text);
      }
    }

    return result;

  }

  isBoolean = (text: string): boolean => {
    let result: boolean = false;
    let val = text.toLowerCase().trim();
    if (val == 'true' || val == 'yes' || val == '1' || val == 'false' || val == 'no' || val == '0') {
      result = true;
    }

    return result;
  };

  getBoolean = (text: string): boolean => {
    let result: boolean = false;
    let val = text.toLowerCase().trim();
    if (val == 'true' || val == 'yes' || val == '1') {
      result = true;
    }

    return result;
  };

  contains = (string: string, stringcontains: string): boolean => {
    if (string == null) return false;

    return string.indexOf(stringcontains) != -1;
  };



  startswith = (string: string, startswith: string): boolean => {
    if (string == null) return false;

    return string.indexOf(startswith) == 0;
  };

  endswith = (string: string, endswith: string): boolean => {
    if (string == null) return false;

    return string.indexOf(endswith, string.length - endswith.length) !== -1;
  };



  isNullEmptyOrWhitespace = (str: string): boolean => {
    let bol: boolean = false;
    if (str == undefined) {
      bol = true;
    } else {
      if (typeof str == 'number') {
        bol = false;
      } else if (str == null) {
        bol = true;
      } else {
        if (str.replace && str.replace(/^\s+|\s+$/g, '') == '') {
          bol = true;
        }
      }
    }

    return bol;
  };

  isNotNullEmptyOrWhitespace = (str: string): boolean => {
    return !this.isNullEmptyOrWhitespace(str);
  };

  right = (str: string, length: number): string => {
    return str.substr(str.length - length, str.length);
  };
  left = (str: string, length: number): string => {
    return str.substr(0, length);
  };

  getRandomText = (charLength: number): string => {
    return Math.random()
      .toString(charLength + 31)
      .substr(2, charLength);
  };



  replaceAll = (originalString: string, oldValue: string, newValue: string, ignoreCase: boolean = false): string => {
    //
    // if invalid data, return the original string
    //
    if (originalString == null || oldValue == null || newValue == null || oldValue.length == 0) return originalString;
    //
    // set search/replace flags
    //
    var Flags: string = ignoreCase ? 'gi' : 'g';
    //
    // apply regex escape sequence on pattern (oldValue)
    //
    var pattern = oldValue.replace(/[-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    //
    // replace oldValue with newValue
    //
    var str = originalString.replace(new RegExp(pattern, Flags), newValue);
    return str;
  };

  /*date */

  getCurrentUtcOffSetMinutes(): number {
    var tzEvalDate = new Date();
    return tzEvalDate.getTimezoneOffset();
  }
  getShortDateString(dte: Date): string {
    let year = dte.getFullYear().toString();
    let month = (dte.getMonth() + 1).toString();
    if (month.length == 1) {
      month = '0' + month;
    }
    let day = dte.getDate().toString();
    if (day.length == 1) {
      day = '0' + day;
    }
    let dateAsString = month + '/' + day + '/' + year;
    return dateAsString;
  }



  convertJsonDateStringsToDates(object: any): void {
    var regexDateIso8601 = /^(\d{4}|\+\d{6})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})\.(\d{1,})(Z|([\-+])(\d{2}):(\d{2}))?)?)?)?$/;

    for (var key in object) {
      if (!object.hasOwnProperty(key)) {
        continue;
      }

      var value = object[key];
      if (value == undefined || value == null) {
        continue;
      }

      var match: any;
      // Check for string properties which look like dates.
      var matchFound: boolean = false;
      var milliseconds: number = null;

      if (typeof value === 'string' && value.length >= 19 && (match = value.match(regexDateIso8601))) {
        milliseconds = Date.parse(match[0]);
        if (!isNaN(milliseconds)) {
          matchFound = true;
        }
      }

      if (matchFound == false) {
        if (typeof value === 'object') {
          // Recurse into object
          this.convertJsonDateStringsToDates(value);
        } else {
          if (value.length == 19) {
            // normally dates are in 2015-12-16T09:22:15.433
            //for format 2016-01-07T00:00:00 that does not have the ending .433
            //try to add it to see if it matches
            var testValue = value + '.433';
            if (typeof value === 'string' && (match = testValue.match(regexDateIso8601))) {
              milliseconds = Date.parse(match[0]);
              if (!isNaN(milliseconds)) {
                matchFound = true;
              }
            }
          }
        }
      }

      if (matchFound == true && milliseconds != null) {
        var proposedDate = new Date(milliseconds);
        if (proposedDate.getFullYear() > 1900) {
          //make sure this doesn't come up with something like
          //Sat Dec 31 1233 19:00:00 GMT-0500 (Eastern Standard Time)
          //which it would if trying to parse a ZipExt string field
          object[key] = proposedDate;
        }
      }
    }
  }



  subtractDays(date: Date, days: number): Date {
    var timeStampSubtract = date.setDate(date.getDate() - days);
    return new Date(timeStampSubtract);
  }

  addDays(date: Date, days: number): Date {
    var timeStampAdd = date.setDate(date.getDate() + days);
    return new Date(timeStampAdd);
  }

  /* number */
  isNotNullAndGreaterThanZero(num: number): boolean {
    //ensure reference to this service
    var result = false;

    if (this.isNotNullOrUndefined(num)) {
      if (num > 0) {
        result = true;
      }
    }
    return result;
  }

  isNullOrLessThanOrEqualToZero(num: number): boolean {
    return !this.isNotNullAndGreaterThanZero(num);
  }

  round(value: number, decimals: number): number {
    var multiplier = Math.pow(10, decimals || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  formatAsCurrencyString(value: number): string {
    var result = '0.00';
    if (value != null && value != undefined) {
      var fl = parseFloat(value.toString());
      result = fl.toFixed(2).toString();
    }
    return '$' + result.toString();
  }
  formatAsDecimalString(value: number, decimals: number): string {
    var result = '0.00';
    if (value != null && value != undefined) {
      var fl = parseFloat(value.toString());
      result = fl.toFixed(decimals).toString();
    }
    return result.toString();
  }

  /* URL */
  getUrlParts(url: string): any {
    var a = document.createElement('a');
    a.href = url;

    return {
      href: a.href,
      host: a.host,
      hostname: a.hostname,
      port: a.port,
      pathname: a.pathname,
      protocol: a.protocol,
      hash: a.hash,
      search: a.search,
    };
  }

  appendObjectPropertyValuesToUrlQuerystring(url: string, object: any): string {
    const urlParams = new URLSearchParams();
    for (const [key, value] of Object.entries(object)) {

      if (this.isNotNullOrUndefined(value)) {
        url = this.appendQueryStringParam(key, String(value), url);
      }


    }
    return url;
  }


  /*Collections */
  getObjectInArrayFromPropertyValue(array: any[], propertyname: string, value: any): any {
    let objReturn: any = null;
    for (let intArrayIndex: number = 0; intArrayIndex < array.length; intArrayIndex++) {
      let objItem: any = array[intArrayIndex];
      if (objItem[propertyname] == value) {
        objReturn = objItem;
        break;
      }
    }

    return objReturn;
  }

  /*Email*/
  isValidEmail(email: string): boolean {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  /*Cookies*/
  getCookieValue(name: string, expectedtype: string): any {
    if (expectedtype == undefined) {
      expectedtype = 'string';
    }
    let result: any;
    let nameEq: string = name + '=';
    let ca: any[] = document.cookie.split(';');
    for (let i: number = 0; i < ca.length; i++) {
      let c: any = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEq) == 0) {
        result = c.substring(nameEq.length, c.length);
        break;
      }
    }

    if (expectedtype.toLowerCase() == 'boolean') {
      if (result.toLowerCase() == 'true') {
        result = true;
      } else {
        result = false;
      }
    }

    if (expectedtype.toLowerCase() == 'number') {
      if (result == '') {
        result = 0;
      } else {
        try {
          result = parseFloat(result);
        } catch (err) {
          result = 0;
        }
      }
    }

    if (expectedtype.toLowerCase() == 'date') {
      if (result == '') {
        result = new Date();
      } else {
        try {
          let timeValue: number = parseInt(result);
          let newDate: Date = new Date();
          newDate.setTime(timeValue);
          result = newDate; //stored as a number since 1970 so we can recreate it when calling get cookie value
        } catch (err) {
          result = new Date();
        }
      }
    }

    if (expectedtype.toLowerCase() == 'object') {
      if (result == '') {
        result = null;
      } else {
        try {
          result = JSON.parse(result);
        } catch (err) {
          result = null;
        }
      }
    }

    return result;
  }

  setCookieValue(cookiename: string, value: any, expiredays: number, expectedtype: string): void {
    if (expectedtype == undefined) {
      expectedtype = 'string';
    }
    if (expiredays == undefined) {
      expiredays = 1;
    }
    let exdate: Date = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    if (expectedtype.toLowerCase() == 'date') {
      value = value.getTime(); //converts to number since 1970 so we can recreate it when calling get cookie value
    }

    if (expectedtype.toLowerCase() == 'object') {
      value = JSON.stringify(value);
    }

    let cvalue: any = value + (expiredays == null ? '' : '; expires=' + exdate.toUTCString()) + '; path=/';
    //var cvalue = escape(value) + ((expiredays == null) ? "" : "; expires=" + exdate.toUTCString()) + "; path=/";
    document.cookie = cookiename + '=' + cvalue;
  }

  deleteCookie(cookiename: string): void {
    document.cookie = cookiename + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  /*Arrays*/
  sortByStringAsc(array: Array<any>, propertyName: string): Array<any> {
    return array.sort(function (a, b) {
      return a[propertyName] > b[propertyName] ? 1 : b[propertyName] > a[propertyName] ? -1 : 0;
    });
  }
  sortByStringDesc(array: Array<any>, propertyName: string): Array<any> {
    return array.sort(function (a, b) {
      return b[propertyName] > a[propertyName] ? 1 : a[propertyName] > b[propertyName] ? -1 : 0;
    });
  }
  sortByNumberAsc(array: Array<any>, propertyName: string): Array<any> {
    return array.sort(function (a, b) {
      return a.value - b.value;
    });
  }
  sortByNumberDesc(array: Array<any>, propertyName: string): Array<any> {
    return array.sort(function (a, b) {
      return b.value - a.value;
    });
  }

 

  arrayContainsEntry(array: Array<any>, propertyName: string, propertyValue: any): boolean {
    let containsItem: boolean = false;
    if (array != undefined && array != null) {
      for (let item of array) {
        if (item[propertyName] == propertyValue) {
          containsItem = true;
          break;
        }
      }
    }

    return containsItem;
  }

  arrayContainsValue(array: Array<any>, value: any): boolean {
    let containsItem: boolean = false;
    if (array != undefined && array != null) {
      for (let item of array) {
        if (item == value) {
          containsItem = true;
          break;
        }
      }
    }

    return containsItem;
  }

  arrayContainsOneOrMoreValues(array: Array<any>, valueArray: Array<any>): boolean {
    let containsItem: boolean = false;
    if (array != undefined && array != null) {
      for (let item of array) {
        if (this.arrayContainsValue(valueArray, item) == true) {
          containsItem = true;
          break;
        }
      }
    }

    return containsItem;
  }

  arrayRemoveEntry(array: Array<any>, entry: any): Array<any> {
    for (var i = array.length - 1; i >= 0; i--) {
      if (array[i] === entry) {
        array.splice(i, 1);
      }
    }

    return array;
  }

  arrayRemoveEntryByPropertyValue(array: Array<any>, propertyName: string, propertyValue: any): Array<any> {
    for (var i = array.length - 1; i >= 0; i--) {
      if (array[i][propertyName] == propertyValue) {
        array.splice(i, 1);
      }
    }

    return array;
  }

  arrayAddEntryToBeginning(array: Array<any>, Entry: any): Array<any> {
    return array.splice(0, 0, Entry);
  }

  arrayMoveItemToIndex(array: Array<any>, oldIndex: number, newIndex: number) {
    if (newIndex >= array.length) {
      var k = newIndex - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }

    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);

    return array;
  }

  // inserts an item into an array sorted by key
  insertOrdered(array: Array<any>, item: any, key: any): number {
    let newPosition = this.orderedLocationOf(item, array, key) + 1;
    array.splice(newPosition, 0, item);
    return newPosition;
  }

  // quicksearch of a sorted array from https://stackoverflow.com/questions/1344500/efficient-way-to-insert-a-number-into-a-sorted-array-of-numbers
  orderedLocationOf(element: any, array: any, key: string, start?: number, end?: number): number {
    start = start || 0;
    end = end || array.length;
    let pivot = Math.floor(start + (end - start) / 2);
    if (end - start <= 1 || array[pivot][key] === element[key]) return pivot;
    if (array[pivot][key] < element[key]) {
      return this.orderedLocationOf(element, array, key, pivot, end);
    } else {
      return this.orderedLocationOf(element, array, key, start, pivot);
    }
  }

  // Gets object property value with a case insensitive key search
  getObjectPropertyValueCaseInsensitive(obj: Object, search: string): any {
    let value = null;

    for (const key in obj) {
      if (key.toLowerCase() === search.toLowerCase()) {
        value = obj[key];
      }
    }

    return value;
  }

  // Gets object property value with a case insensitive key search
  copyObjectPropertyValues(sourceObj: Object, destinationObj: Object): any {
    let value = null;

    for (const key in sourceObj) {
      destinationObj[key] = sourceObj[key];
        
    }

    return destinationObj;
  }

  convert24Hourto12Hour(time: string) {
    return new Date('1970-01-01T' + time + ':00Z').toLocaleTimeString('en-US', {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  convert12Hourto24Hour(time: string) {
    const [validatedTime, modifier] = time.split(' ');

    let [hours, minutes] = validatedTime.split(':');

    if (hours.length < 2) {
      hours = '0' + hours;
    }

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM' || modifier === 'Midnight') {
      hours = (parseInt(hours, 10) + 12).toString();
    }

    return `${hours}:${minutes}`;
  }

  listToCsv(list: Array<number>) {

    var csv = "";
    for (var num of list) {
      if (this.isNullEmptyOrWhitespace(csv)) {
        csv = num.toString();
      } else {
        csv = csv + "," + num.toString();
      }
    }

    return csv;

  }

}
