 
import { NameValueStringListDto } from 'src/app/shared/dto/name-value-string-list-dto';
import { NameValueStringDto }  from '../../../shared/dto/name-value-string-dto';
 


export class TenantDatasetColumnDto {

    public constructor(){

        this.name = "";
        this.aliasesCsv = "";
        this.isIdColumn = false;
        this.dataType = "";
        this.role = "";
        this.valueAliases = new Array<NameValueStringDto>();
    }
    name: string;
    aliasesCsv: string;
    // description: string;
     isIdColumn: boolean;
    // isCurrencyColumn: boolean;
    // isCalculatedField: boolean;
    // Type: string;
    dataType: string;
    role: string;
    // semanticType: string;
    // semanticSubType: string;
    // displayName: string;
    // aliasNames: string[];
    // defaultAggregation: string;
    valueAliases : Array<NameValueStringDto>
   
}