import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';

import { LocalStorageService } from '../shared/storage/local-storage.service';
import { DashboardService } from '../dashboard/shared/dashboard.service';

import { createEmbeddingContext, DashboardContentOptions, QSearchExperience, EmbeddingEvents, ExperienceFrameMetadata, QSearchContentOptions, EmbeddingContext, FrameOptions, GenerativeQnAContentOptions, GenerativeQnAPanelOptions, GenerativeQnAFullPanelOptions, GenerativeQnASearchBarOptions } from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../auth/shared/auth.service';
import { LogService } from '../shared/log/log.service';
import { UtilService } from '../shared/util/util.service';

import { AdminService } from '../admin/shared/admin.service';
import { RabTenantDto } from '../admin/shared/dto/rab-tenant-dto';
import { TenantDatasetDto } from '../admin/shared/dto/tenant-dataset-dto';
import { NameValueNumberDto } from '../shared/dto/name-value-number-dto';

@Component({
  selector: 'q-search',
  templateUrl: './q-search.component.html',
  styleUrls: ['./q-search.component.css']
})
export class QSearchComponent implements OnInit {


  private _productId: string = "";
  private _tenantId: string = "";

  public qSearchEmbedUrlRaw: string = "";
  private _embeddedQSearchExperience: QSearchExperience;

  @Input() topicType = "";
  @Input() placeholderText = "";
  @Input() containerId = "";

  public customDatasets: Array<TenantDatasetDto> = new Array<TenantDatasetDto>();


  constructor(private _authService: AuthService, private _logService: LogService, private _utilService: UtilService, private _dashboardService: DashboardService, private _sanitizer: DomSanitizer, private _localStorageService: LocalStorageService, private _adminService: AdminService) {


  }

  ngOnInit() {

    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;

    this.loadQuicksightsElements(this.containerId);





  }

  loadQuicksightsElements = (containerId: string): void => {

    if (this._utilService.hasQueryStringParam("topicId") == true) {

      var topicId = this._utilService.getQueryStringParamValue("topicId", "string") ;
      this._adminService.getDatasets(this._productId, this._tenantId).then((data: Array<TenantDatasetDto>) => {
        this.customDatasets = data;

        var topicId = this._utilService.getQueryStringParamValue("topicId", "string") ;
        var topicDataset = this.customDatasets.filter(x => x.topicId == topicId)[0];
        this.placeholderText = "Ask a question about " + topicDataset.name;

        var promise0 = this._dashboardService.getQSearchEmbedUrlFromTopicId(topicId).then((data: string) => {
  
          //this.dashboardEmbedUrlRaw = this._sanitizer.bypassSecurityTrustResourceUrl(data);
          this.qSearchEmbedUrlRaw = data;        
          this.embedQuicksightTools(this.containerId, data);
  
          
        });
        
      });
      
    }
    else{
      if (this._utilService.isNotNullEmptyOrWhitespace(this.topicType)) {
        var promise1 = this._dashboardService.getQSearchEmbedUrlFromTopicType(this.topicType).then((data: string) => {
  
          //this.dashboardEmbedUrlRaw = this._sanitizer.bypassSecurityTrustResourceUrl(data);
          this.qSearchEmbedUrlRaw = data;
  
          this.embedQuicksightTools(this.containerId, data);
        });
  
      } else {
        var promise1 = this._dashboardService.getQSearchEmbedUrl().then((data: string) => {
  
          //this.dashboardEmbedUrlRaw = this._sanitizer.bypassSecurityTrustResourceUrl(data);
          this.qSearchEmbedUrlRaw = data;
  
          this.embedQuicksightTools(this.containerId, data);
        });
      }
    }
    




  }



  embedQuicksightTools = async (containerId: string, url: string) => {


    var frameOptionsQSearch: FrameOptions = {
      url: url, //"<YOUR_EMBED_URL>", // replace this value with the url generated via embedding API
      container: '#' + containerId,
      //resizeHeightOnSizeChangedEvent: true,
      // withIframePlaceholder: true
      // height: "100%",
      // width: "100%"
      //onChange: (changeEvent: EmbeddingEvents, metadata: ExperienceFrameMetadata) => {
      //  switch (changeEvent.eventName) {
      //    case 'FRAME_MOUNTED': {
      //      this._logService.info("QSearch - Do something when the experience frame is mounted.");
      //      break;
      //    }
      //    case 'FRAME_LOADED': {
      //      this._logService.info("QSearch - Do something when the experience frame is loaded.");
      //      break;
      //    }
      //  }
      //},
    };


    //hideTopicName: false,
    //hideIcon: true,
    //theme: '<YOUR_THEME_ID>',

    var specifiedTopic = false;
    var allowTopicSelection = true;
    if (this._utilService.isNotNullEmptyOrWhitespace(this.topicType)) {
      specifiedTopic = true;
      allowTopicSelection = false;
    }

    // let panelOptions: GenerativeQnAPanelOptions = {
    //   panelType: "FULL",
    //   title: "AI Assistant",
    //   showQIcon: false
    // };

    let panelOptions: GenerativeQnAPanelOptions = {
      panelType: "FULL",
      title: "AI Data Analyst",
      showQIcon: false
    };

    

    let contentOptionsQSearch: GenerativeQnAContentOptions = {

      allowTopicSelection: allowTopicSelection,
      allowFullscreen: true,
      searchPlaceholderText: this.placeholderText,
      showTopicName: false,
      showPinboard: false,
      panelOptions: panelOptions,
      onMessage: async (messageEvent: EmbeddingEvents, metadata?: ExperienceFrameMetadata) => {
        switch (messageEvent.eventName) {
          //case 'Q_SEARCH_OPENED': {
          //  this._logService.info("QSearch - Do something when Q Search content expanded");
          //  break;
          //}
          //case 'Q_SEARCH_CLOSED': {
          //  this._logService.info("QSearch - Do something when Q Search content collapsed");
          //  break;
          //}
          //case 'Q_SEARCH_SIZE_CHANGED': {
          //  this._logService.info("QSearch - Do something when Q Search size changed");
          //  break;
          //}
          //case 'CONTENT_LOADED': {
          //  this._logService.info("QSearch - Do something when the Q Search is loaded.");
          //  break;
          //}
          case 'ERROR_OCCURRED': {
            this._logService.info("QSearch - Do something when the Q Search fails loading.");
            break;
          }
        }
      }
    };
    await this._dashboardService.getEmbeddingContext().embedGenerativeQnA(frameOptionsQSearch, contentOptionsQSearch);





  };



}
